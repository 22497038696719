import React from 'react';
import './bottomBanner.scss';
import { useLanguage } from '../../context/languageContext';
import messages from '../../data/message-custom-confirm.json';
import Button from '../button/button';
import Icon from '../icon/icon';

const BottomBanner = ({ 
  onKeepLocal, 
  onUseUrl, 
  showBanner, 
  setShowBanner,
  children,
  simple = false
}) => {
  const { language } = useLanguage();
  const messageData = messages[language]?.bottomBanner || messages['pt'].bottomBanner;

  // Se for simple, retorna apenas o container com children
  if (simple) {
    return (
      <div className="bottom-banner simple">
        <div className="bottom-banner-content">
          {children}
        </div>
      </div>
    );
  }

  // Retorna null se showBanner for false
  if (!showBanner) return null;

  const handleUseUrl = () => {
    if (onUseUrl) onUseUrl();
    setShowBanner(false);
  };

  const handleKeepLocal = () => {
    if (onKeepLocal) onKeepLocal();
    setShowBanner(false);
  };

  return (
    <div className="bottom-banner">
      <div className='banner-top-bar'>
        <Button onlyIcon onClick={() => setShowBanner(false)}>
          <Icon name={"menu-collapse"} />
        </Button>
      </div>
      <div className="bottom-banner-content">
        <h2>{messageData.title}</h2>
        {children}
        <div className='banner-actions'>
          <Button onClick={handleUseUrl}>{messageData.useUrl}</Button>
          <Button onClick={handleKeepLocal} primary>{messageData.keepLocal}</Button>
        </div>
      </div>
    </div>
  );
};

export default BottomBanner;
