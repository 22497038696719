import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const MasonryContainer = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const MasonryGrid = styled.div`
  width: 30%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const BookItem = styled.div`
  cursor: pointer;
  transition: transform 0.3s ease, opacity 0.3s ease;
  transform: scale(${props => props.selected ? 1 : 0.95});
  opacity: ${props => props.selected ? 1 : 0.7};
  &:hover {
    opacity: 1;
  }
`;

const BookImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  background: url('./images/thumbnails/cover-image-fallback.png') no-repeat center;
  background-size: cover;
`;

const BookInfo = styled.div`
  width: 70%;
  padding-left: 24px;

  @media (max-width: 768px) {
    width: 100%;
    padding-left: 0;
    margin-top: 16px;
  }
`;

function BookMasonry({ books }) {
  const [selectedBook, setSelectedBook] = useState(books[0] || {});
  const [columns, setColumns] = useState([[], [], []]);
  const [loadedImages, setLoadedImages] = useState({});

  useEffect(() => {
    const newColumns = [[], [], []];
    books.forEach((book, index) => {
      newColumns[index % 3].push(book);
    });
    setColumns(newColumns);
    setSelectedBook(books[0] || {});

    // Preload images
    books.forEach(book => {
      const img = new Image();
      img.src = book.thumbnail;
      img.onload = () => {
        setLoadedImages(prev => ({
          ...prev,
          [book.isbn]: true
        }));
      };
    });
  }, [books]);

  const handleImageError = (e, isbn) => {
    e.target.onerror = null;
    e.target.src = './images/thumbnails/cover-image-fallback.png';
    setLoadedImages(prev => ({
      ...prev,
      [isbn]: true
    }));
  };

  return (
    <MasonryContainer>
      <MasonryGrid>
        {columns.map((column, columnIndex) => (
          <div key={columnIndex} style={{width: 'calc(33.33% - 11px)'}}>
            {column.map((book) => (
              <BookItem 
                key={book.isbn} 
                onClick={() => setSelectedBook(book)}
                selected={book.isbn === selectedBook.isbn}
              >
                <BookImage
                  src={loadedImages[book.isbn] ? book.thumbnail : './images/thumbnails/cover-image-fallback.png'}
                  alt={book.title}
                  onError={(e) => handleImageError(e, book.isbn)}
                  style={{
                    opacity: loadedImages[book.isbn] ? 1 : 0.7,
                    transition: 'opacity 0.3s ease'
                  }}
                />
              </BookItem>
            ))}
          </div>
        ))}
      </MasonryGrid>
      <BookInfo>
        <h3>{selectedBook.title}</h3>
        <p>by {selectedBook.authors}</p>
        <small>{selectedBook.description}</small>
      </BookInfo>
    </MasonryContainer>
  );
}

export default BookMasonry;
