import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import content from '../../data/list.json';
import List from '../../components/list/list';
import { useLanguage } from '../../context/languageContext';
import Tag from '../../components/tag/tag';
import VideoYoutube from '../../components/videoYoutube/videoYoutube';
import SelectBox from '../../components/selectBox/selectBox';
import './projects.scss';

const CACHE_DURATION = 30 * 60 * 1000; // 30 minutos
const CACHE_KEY = 'projectsData';
const VERSION = '1.0';

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const Projects = () => {
  const { language } = useLanguage();
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [filteredList, setFilteredList] = useState([]);
  const [selectedContentType, setSelectedContentType] = useState('All');
  const [isLoading, setIsLoading] = useState(true);

  const contentTypes = [
    'All', 
    ...new Set(
      (data.list?.map(item => capitalizeFirstLetter(item.contentType)) || [])
    )
  ];

  const checkCacheValidity = () => {
    const cache = localStorage.getItem(CACHE_KEY);
    if (!cache) return false;

    const { timestamp, version, data } = JSON.parse(cache);
    if (version !== VERSION) return false;
    if (Date.now() - timestamp > CACHE_DURATION) return false;
    
    return data;
  };

  const loadData = async () => {
    setIsLoading(true);
    
    try {
      // Verificar cache
      const cachedData = checkCacheValidity();
      if (cachedData) {
        console.log('Loading projects from cache');
        setData(cachedData[language] || cachedData.pt || {});
        setIsLoading(false);
        return;
      }

      // Se não há cache válido, carregar dados novos
      console.log('Loading fresh projects data');
      const newData = {
        timestamp: Date.now(),
        version: VERSION,
        data: content
      };
      
      localStorage.setItem(CACHE_KEY, JSON.stringify(newData));
      setData(content[language] || content.pt || {});
      
    } catch (error) {
      console.error('Error loading projects:', error);
      // Tentar usar dados do cache mesmo se expirado em caso de erro
      const cache = localStorage.getItem(CACHE_KEY);
      if (cache) {
        const { data } = JSON.parse(cache);
        setData(data[language] || data.pt || {});
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData();
    
    const searchParams = new URLSearchParams(location.search);
    const contentTypeParam = searchParams.get('content');
    
    if (contentTypeParam) {
      setSelectedContentType(capitalizeFirstLetter(contentTypeParam));
    }
  }, [language, location.search]);

  useEffect(() => {
    if (data.list) {
      const filtered = selectedContentType === 'All' 
        ? data.list 
        : data.list.filter(item => 
            capitalizeFirstLetter(item.contentType) === selectedContentType
          );
      
      setFilteredList(filtered);
    }
  }, [data.list, selectedContentType]);

  const handleContentTypeChange = (value) => {
    setSelectedContentType(value);
    
    const searchParams = new URLSearchParams(location.search);
    if (value === 'All') {
      searchParams.delete('content');
    } else {
      searchParams.set('content', value.toLowerCase());
    }
    
    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
  };

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <>
      <List>
        <div className='content-type-filter'>
          <SelectBox 
            options={contentTypes} 
            selected={selectedContentType} 
            onChange={handleContentTypeChange} 
          />
        </div>

        <div className='list-projects'>
          {filteredList.map((item, index) => (
            <div className='project' key={index}>
              <div className='list-item'>
                <div className='col-2'>
                  {item.videoPreview && (
                    <VideoYoutube
                      videoId={item.videoYoutubeId}
                      videoPreview={item.videoPreview}
                      service={item.videoService}
                      linkPreview={item.links && item.links[0]?.url}
                      lazyLoad={true}
                    />
                  )}
                  {/* {item.videoYoutubeId ? <h5>Video</h5> : ''}
                  {!item.videoYoutubeId && item.videoPreview ? <h5>Preview</h5> : ''} */}
                </div>
                <div className='col-1'>
                  <h3>{item.title}</h3>
                  {item.tags && (
                    <div className="tags-group">
                      {item.status && <Tag highlight>{item.status}</Tag>}
                      {item.tags.map((tag, idx) => (
                        <Tag key={idx} className="tag">
                          {tag}
                        </Tag>
                      ))}
                    </div>
                  )}
                  <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
                  {item.links && item.links.length > 0 ? <h5>Related links</h5> : ''}
                  <div className='list-icons'>
                    {item.links && item.links.map((link, idx) => (
                      <div key={idx}>
                        <a href={link.url} target="_blank" rel="noopener noreferrer">
                          {link.url_label}
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </List>
    </>
  );
};

export default Projects;
