import ScrollToTop from '../../utils/scroll-to'
import Page from '../page/page'
import './container.scss'



const Container = ({...props}) => {
    return (
        <div className={`container ${props.pageKey}`}><ScrollToTop /><Page>{props.children}</Page></div> 
    )
}
//{props.footer ? props.footer : null}

export default Container