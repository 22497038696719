import React, { useState, useEffect } from 'react';
import booksData from './../../data/books-data.json';
import readingBooks from './../../data/reading.json';
import BookMasonry from './BookMasonry';
import BottomBanner from '../bottomBanner/bottomBanner';

function BookList() {
  const [books, setBooks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [language, setLanguage] = useState('en');
  const [content, setContent] = useState(readingBooks['en'][0]);

  const CURRENT_VERSION = '1.0';
  const CACHE_DURATION = 30 * 60 * 1000; // 30 minutos em milissegundos

  useEffect(() => {
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      setLanguage(storedLanguage);
    }
  }, []);

  useEffect(() => {
    setContent(readingBooks[language][0]);
    loadBooks();
  }, [language]);

  const checkCacheValidity = () => {
    const cacheInfo = JSON.parse(localStorage.getItem('booksCache') || '{}');
    const now = Date.now();

    if (!cacheInfo.timestamp || !cacheInfo.version) return false;
    if (cacheInfo.version !== CURRENT_VERSION) return false;
    if (now - cacheInfo.timestamp > CACHE_DURATION) return false;

    return true;
  };

  const loadBooks = async () => {
    setIsLoading(true);
    console.log('Verificando cache...');

    if (checkCacheValidity()) {
      console.log('Usando dados do cache');
      const cachedData = JSON.parse(localStorage.getItem('booksData'));
      setBooks(cachedData);
      setIsLoading(false);
      return;
    }

    console.log('Buscando dados novos da API');
    try {
      const fetchedBooks = await Promise.all(
        booksData.map(async (book) => {
          const details = await fetchBookDetails(book.isbn);
          return { ...book, ...details };
        })
      );

      localStorage.setItem('booksData', JSON.stringify(fetchedBooks));
      localStorage.setItem('booksCache', JSON.stringify({
        timestamp: Date.now(),
        version: CURRENT_VERSION
      }));

      setBooks(fetchedBooks);
    } catch (err) {
      setError(`Erro ao buscar livros: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchBookDetails = async (isbn) => {
    try {
      const response = await fetch(
        `https://www.googleapis.com/books/v1/volumes?q=isbn:${isbn}&langRestrict=${language}`
      );
      
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      
      const data = await response.json();
      const bookData = data.items?.[0]?.volumeInfo || {};
      
      const details = {
        title: bookData.title || 'Título Desconhecido',
        authors: bookData.authors?.join(', ') || 'Autor Desconhecido',
        description: bookData.description || 'Sem descrição disponível',
        thumbnail: bookData.imageLinks?.thumbnail || '/images/thumbnails/cover-image-fallback.png'
      };

      return details;
    } catch (error) {
      console.error(`Erro ao buscar detalhes do livro ${isbn}:`, error);
      return {
        title: 'Título Desconhecido',
        authors: 'Autor Desconhecido',
        description: 'Sem descrição disponível',
        thumbnail: '/images/thumbnails/cover-image-fallback.png'
      };
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Erro: {error}</div>;

  return (
    <div>
      <h2>{content.title}</h2>
      <div dangerouslySetInnerHTML={{ __html: content.description }} />
      <BookMasonry books={books} />
      <div style={{ opacity: .5, marginTop: "5vh" }}>
        <BottomBanner simple>This page is under development. I'm fetching book details and images from an API. If it takes too long, feel free to ask for assistance.</BottomBanner>
      </div>
    </div>
  );
}

export default BookList;
