import React, { useEffect, useState } from 'react';
import Icon from '../../components/icon/icon';
import content from '../../data/career.json';
import { useLanguage } from '../../context/languageContext';
import Tag from '../../components/tag/tag';
import List from '../../components/list/list';
import './career.scss';

const Career = () => {
  const { language } = useLanguage();
  const [data, setData] = useState([]);
  const [isLongVersion, setIsLongVersion] = useState(true);

  useEffect(() => {
    setData(content[language] || []);
  }, [language]);

  if (!data || !Array.isArray(data.list)) {
    return <div className="loading">Loading...</div>;
  }

  const renderLongVersion = () => (
    <List>
      {data.list.map((item, index) => (
        <div key={index}>
          <div>
            <h3>{item.year}</h3>
            {item.tags && item.tags.length > 0 && (
              <div className="tags-group">
                {item.tags.map((tag, tagIndex) => (
                  <Tag key={tagIndex}>{tag}</Tag>
                ))}
              </div>
            )}
            <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
            {item.links && item.links.length > 0 && (
              <div className="list-icons">
                {item.links.map((link, linkIndex) => (
                  <div key={linkIndex}>
                    <a href={link.url} target="_blank" rel="noopener noreferrer">
                      {link.icon && <Icon name={link.icon} />}
                      {link.url_label && <span>{link.url_label}</span>}
                    </a>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      ))}
    </List>
  );

  const renderShortVersion = () => (
    <div className="short-version">
      <div dangerouslySetInnerHTML={{ __html: data.shortDescription }}></div>
    </div>
  );

  return (
    <div className="career-container">
      <h2>{data.pageTitle}</h2>
      <br/>
      {renderShortVersion()}
      <br/>
      <h3>{data.titleDetailedVersion}</h3>
      <br/>
      {renderLongVersion()}
    </div>
  );
};

export default Career;
